<template>
    <div id="abnormalManage">
<!--        <CSTabBar :tabbar="tabBarList" :checkedTab="tabBarType" @changeTabBar="changeTabBar"></CSTabBar>-->
        <div id="searchBox" class="filter-panel" v-show="tabBarType === 0">
            <CSSelect style="width:210px;">
                <el-date-picker
                    v-model="queryParams.startTime"
                    type="datetime"
                    format="yyyy-MM-dd HH:mm"
                    value-format="yyyy-MM-dd HH:mm"
                    placeholder="请选择开始时间"
                    prefix-icon="el-icon-time"
                    :editable="false"
                >
                </el-date-picker>
            </CSSelect>
            <span
                style="
                display: inline-block;
                vertical-align:middle;
                margin:0px 20px 15px 0px;
                height: 2px;
                width:20px;
                background-color:#999;
                "
            ></span>
            <CSSelect style="width:210px;">
                <el-date-picker
                    v-model="queryParams.endTime"
                    type="datetime"
                    format="yyyy-MM-dd HH:mm"
                    value-format="yyyy-MM-dd HH:mm"
                    placeholder="请选择结束时间"
                    prefix-icon="el-icon-time"
                    :editable="false"
                >
                </el-date-picker>
            </CSSelect>
            <input
                type="text"
                style="width:200px;"
                placeholder="搜索抓拍监控/监控位置"
                v-model="queryParams.search"
                class="cs-input"
            />
            <CSSelect>
                <select style="width:150px" v-model="queryParams.type">
                    <option value="">全部异常</option>
                    <option
                        v-for="(typeName, type) in monitoringType"
                        :value="type"
                        :key="type"
                    >{{typeName}}</option>
                </select>
            </CSSelect>
            <CSSelect>
                <select style="width:150px" v-model="queryParams.isSolve">
                    <option value="">是否处理不限</option>
                    <option v-for="(typeName, type) in solveType"
                      :value="type" :key="type"
                    >
                    {{typeName}}
                    </option>
                </select>
            </CSSelect>
            <CSSelect>
                <select style="width:150px" v-model="queryParams.handlerUser">
                    <option value="">全部处理人</option>
                    <option v-for="(staff,index) in staffList"
                        :value="staff.id"
                        :key="`${index}_${staff.id}`"
                    >
                        {{staff.name}}
                    </option>
                </select>
            </CSSelect>
            <button
                type="button"
                class="btn btn-primary btn-sm"
                @click="getAbnormalData()"
            >
                查询
            </button>
        </div>
        <div id="tableBox" class="result-panel">
            <template v-if="tabBarType === 0">
                <CSTable :thead-top="filterHeight">
                    <template v-slot:thead>
                        <tr>
                            <th>抓拍时间</th>
                            <th>抓拍监控</th>
                            <th>监控位置</th>
                            <th>异常</th>
                            <th>抓拍异常图像</th>
                            <th>是否处理</th>
                            <th>处理人</th>
                            <th>处理结果</th>
                            <th>处理时间</th>
                        </tr>
                    </template>
                    <template v-slot:tbody>
                        <tr v-for="abnormal in abnormalInfoList"
                            :key="abnormal.id"
                        >
                            <td class="date-td">{{abnormal.createTime}}</td>
                            <td>
                                    <span
                                        class="allow-click"
                                        @click="checkCameraDetail(abnormal.cameraId)"
                                    >{{abnormal.numbering}}</span>
                            </td>
                            <td>{{abnormal.specificLocation}}</td>
                            <td>{{monitoringType[abnormal.type]}}</td>
                            <td style="min-width:200px">
                                <template v-if="abnormal.photoUrl || abnormal.panorama">
                                    <img v-if="abnormal.photoUrl" @click="checkImg(abnormal.photoUrl, '查看人脸')" style="cursor:pointer" width="80px" height="80px" :src="abnormal.photoUrl"/>
                                    <img v-if="abnormal.panorama" @click="checkImg(abnormal.panorama, '查看全景')" style="cursor:pointer;margin-left: 10px" width="80px" height="80px" :src="abnormal.panorama"/>
                                </template>
                                <template v-else>
                                    -
                                </template>
                            </td>
                            <td>不需要处理</td>
                            <td>-</td>
                            <!--                                <td>{{solveType[abnormal.isLove]}}</td>
                                                            <td>{{abnormal.handlerUser}}</td>-->
                            <td>
                                <span v-if="abnormal.handlerResult === ''">-</span>
                                <span
                                    v-else
                                    class="allow-click"
                                    @click="checkDealResult(abnormal)"
                                >
                                        查看
                                    </span>
                            </td>
                            <td>{{abnormal.handlerTime || '-'}}</td>
                        </tr>
                    </template>
                </CSTable>
                <Pagination
                    name="Pagination"
                    componentName="Pagination"
                ></Pagination>
            </template>

<!--            <div v-show="tabBarType === 1" class="config-panel">
                <div style="padding:20px 30px;">
                  <template  v-for="(item, key) in abnormalItems">
                    <div class="config-item" :key="key">
                      <div style="width:266px;" class="config-item-label">
                        {{item.text}}
                      </div>
                      <div class="config-item-radio">
                        <label  style="cursor:pointer;" v-for="option in item.options" @click="turnOn(item, option.id)">
                          <span :class="(item.children && option.id === -1 && item.val !== 0) || item.val === option.id ? 'class-b' : 'class-a'"></span>
                          <span>{{option.text}}</span>
                        </label>
                      </div>
                    </div>
                    <div class="config-item" :key="key+'child'" v-if="item.children && item.val !== 0">
                      <template v-for="child in item.children">
                        <div class="config-item-radio" style="margin-left: 0;display: block;color: #444;">
                          <label  style="cursor:pointer;" @click="turnOn(item, child.id)">
                            <span :class="child.id === item.val ? 'class-b' : 'class-a'"></span>
                            {{child.text}}
                          </label>
                        </div>
                      </template>


                    </div>
                  </template>
                    <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        @click="saveStandard()"
                    >
                        保存
                    </button>
                </div>
            </div>-->
        </div>
        <CSDialog
            dialog-title="查看处理结果"
            dialog-width='540px'
            class="tenant-detail-dialog"
            :dialog-visible="dealResultVisible"
            @onClose="dealResultVisible = false"
            :dialog-show-confirm-btn="false"
            dialog-cancel-btn-text="关闭"
        >
            <template v-slot:dialog-content>
                <div style="text-align:center;font-size:24px;padding:30px; ">
                    {{handlerResult.text}}
                </div>
                <img v-if="!handlerResult.photo && handlerResult.photo !== ''" :src="handlerResult.photo" alt="">
            </template>
        </CSDialog>
        <ViewImageModal
            name="ViewImageModal"
            componentName="ViewImageModal"
        ></ViewImageModal>
        <TargetPersonVideo></TargetPersonVideo>
        <CameraControls>
            <template v-slot:video>
                <video ref="video" src=""></video>
            </template>
        </CameraControls>
    </div>
</template>

<script>
import {MENUSTATUSLIST} from "@/constant"
import CSTabBar from "@/components/common/CSTabBar";
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";
import ViewImageModal from "@/components/ViewImageModal";
import Pagination from "@/components/Pagination";
import TargetPersonVideo from "@/components/SecurityCamera/targetPersonVideo";
import {
    closeRealTimeStreamUrl, openRealTimeStreamUrl,
    queryAbnormalPushConfigUrl,
    queryCapturePhotoAbnormalUrl,
    saveAbnormalPushConfigUrl
} from "@/requestUrl";
import CameraControls from "@/components/SecurityCamera/cameraControls";
import {initStreamVideoPlayer} from "@/utils";
import CSTable from "@/components/common/CSTable";

export default {
    components:{
        CSTable,
        CameraControls,
        CSTabBar,
        CSSelect,
        CSDialog,
        ViewImageModal,
        Pagination,
        TargetPersonVideo,
    },
    data(){
        return {
            videoPlayers: {},
            filterHeight: 0,
            isFullscreen: false,
            queryParams: {
              startTime: dayjs().subtract(7, "days").format("YYYY-MM-DD")+ ' 00:00',
              endTime: dayjs().format("YYYY-MM-DD 23:59"),
              handlerUser: '',        //处理人id
              isSolve: '',            //处理结果 0未处理 1已处理 2不需要处理
              search: "",            //搜索框内容
              type: '' //异常类型    1人员聚集，2未带口罩，3吸烟，4跌倒，5像机被遮挡，6区域入侵，7车辆违停，8VIP，9可疑人
            },
            tabBarType:0,
            tabBarList:{
                0:"异常抓拍",
                // 1:"设置工单推送",
            },
            solveType: {
              1: '未处理',
              2: '已处理',
              0: '不需要处理'
            },
            monitoringType: {
                1: "人员聚集",
                2: "未戴口罩",
                3: "吸烟",
                4: "跌倒",
                // 5: "监控遮挡",
                6: "区域入侵",
                7: "车辆违停",
                8: "VIP",
                9: "可疑人"
            },
            dealResultVisible:false,
            abnormalInfoList:[],
            staffList: [],
            handlerResult: {    // 处理结果
              text: '',
              photo: '',
            },
            abnormalItems: {
              isPeopleGathering: {
                text: '监控抓拍到人员聚集，向保安主管发送工单',
                val: 0,
                options: [{id: 1, text: '开启'}, {id: 0, text: '关闭'}]
              }, //是否启用人员聚集检测    0不启用，1启用
              isNoMask: {
                text: '监控抓拍到未带口罩，向保安主管发送工单',
                val: 0,
                options: [{id: 1, text: '开启'}, {id: 0, text: '关闭'}]
              }, //是否启用未戴口罩检测   0不启用，1启用
              isSmoke: {
                text: '监控抓拍到吸烟，向保安主管发送工单',
                val: 0,
                options: [{id: 1, text: '开启'}, {id: 0, text: '关闭'}]
              }, //是否启用吸烟检测        0不启用，1启用
              isFall: {
                text: '监控抓拍到跌倒，向保安主管发送工单',
                val: 0,
                options: [{id: 1, text: '开启'}, {id: 0, text: '关闭'}]
              }, //是否启用跌倒检测      0不启用，1启用
              isObstructView: {
                text: '监控抓拍到监控遮挡，向保安主管发送工单',
                val: 0,
                options: [{id: 1, text: '开启'}, {id: 0, text: '关闭'}]
              }, //是否启用监控遮挡检测   0不启用，1启用
              isRegionInvade: {
                text: '监控抓拍到区域入侵，向保安主管发送工单',
                val: 0,
                options: [{id: 1, text: '开启'}, {id: 0, text: '关闭'}]
              }, //是否启用区域入侵检测    0不启用，1启用
              isIllegalParking: {
                text: '监控抓拍到车辆违停，向保安主管发送工单',
                val: 0,
                options: [{id: 1, text: '开启'}, {id: 0, text: '关闭'}]
              }, //是否启用汽车违停检测   0不启用，1启用
              isPeopleCounting: {
                text: '人流统计检测',
                val: 0,
                options: [{id: 1, text: '开启'}, {id: 0, text: '关闭'}]
              }, //是否启用人流统计检测    0不启用，1启用
              vipSendTicket: {
                text: '监控抓拍到VIP，向保安主管发送工单',
                val: 0,
                options: [{id: -1, text: '开启'}, {id: 0, text: '关闭'}],
                children: [
                  {id: 1, text: '当日监控首次抓拍到VIP，向保安主管发送工单即可'},
                  {id: 2, text: '所有监控每次抓拍到VIP，都向保安主管发送工单'}
                ]
              }, //是否启用关注人检测    0不启用，1启用
              suspectSendTicket: {
                text: '监控抓拍到可疑人，向保安主管发送工单',
                val: 0,
                options: [{id: -1, text: '开启'}, {id: 0, text: '关闭'}],
                children: [
                  {id: 1, text: '当日监控首次抓拍到可疑人，向保安主管发送工单即可'},
                  {id: 2, text: '所有监控每次抓拍到可疑人，都向保安主管发送工单'}
                ]
              },
            },
        }
    },
    created(){
         this.$vc.getStaffList({
             regionCode: this.$vc.getCurrentRegion().code || "",
             departmentCode: "",
             jobLevel: "",
             isOnTheJob: true,
         }).then(res => {
             this.staffList = res;
         });
        // this.getAbnormalPushConfig();
        this.getAbnormalData();

    },
    mounted(){
        window.addEventListener("keydown",this.getAbnormalDataDown);
        this.$vc.on(this.$route.path, "pagination_page", "event", this.getAbnormalData);
        window.addEventListener('resize', () => {
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        })
    },
    updated() {
        this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    },
    destroyed(){
        window.removeEventListener("keydown",this.getAbnormalDataDown);
    },
    deactivated(){
        window.removeEventListener("keydown",this.getAbnormalDataDown);
    },
    methods:{
        getAbnormalDataDown(e){
            if(e.keyCode == 13){
                this.getAbnormalData();
            }
        },

        fullScreen(target, act = 'requestFullscreen', el) {
            const cameraWindow = el || document.querySelector(".cameraWindow" + target?.id);
            if (act === 'requestFullscreen') {
                document.body.requestFullscreen();
                this.$vc.emit(this.$route.path, "cameraManage", "cameraControls", {target, visible: true});
            }
            if (!cameraWindow) {
                return ;
            }
            document.body.addEventListener('fullscreenchange', e => {
                if (document.fullscreenElement) {
                    cameraWindow.style.position = 'fixed';
                    cameraWindow.style.top = '0';
                    cameraWindow.style.left = '0';
                    cameraWindow.style.right = '0';
                    cameraWindow.style.bottom = '0';
                    cameraWindow.style.width = '100vw';
                    cameraWindow.style.height = '100vh';
                    cameraWindow.style.background = 'rgba(0, 0, 0, 1)';
                    cameraWindow.style.zIndex = '2004';
                    this.isFullscreen = true;
                } else {
                    this.isFullscreen = false;
                    cameraWindow.style.position = 'relative';
                    cameraWindow.style.width = '540px';
                    cameraWindow.style.height = '340px';
                    cameraWindow.style.zIndex = '1';
                    cameraWindow.style.background = 'rgba(0, 0, 0, .2)';
                    this.$vc.emit(this.$route.path, "cameraManage", "cameraControls", {target: null, visible: false});
                }
            })

        },
        /**
         * 列表查看全屏监控
         * @param {Number} camearId 监控id
         * */
        checkCameraDetail(cameraId) {
            console.log(this.$refs.video, '监控摄像头');
            this.queryCameraStreamUrl(cameraId, this.$refs.video);
            this.fullScreen({id: cameraId}, 'requestFullscreen', this.$refs.video);
        },
        /**
         * 关闭视频流
         * @param {Number} id 监控id
         * */
        closeCameraStream(id) {
            try {
                this.videoPlayers[id].pause();
            } catch (err) {
                console.log(err, '暂停视频错误');
            }
            return this.$fly.get(closeRealTimeStreamUrl, {id}).then(res => res.data || '')
        },
        /**
         * 获取视频流地址
         * @param {Number} id 监控id
         * @param {String} videoEl 显示监控的标签id或者元素
         * */
        async queryCameraStreamUrl(id, videoEl) {
            const data = await this.$fly.get(openRealTimeStreamUrl, {id});
            const cameraStreamUrl = data.data;
            try {
                console.log('开始调用初始化监控函数', id);
                const player = await initStreamVideoPlayer((err) => {
                        console.log(err, '监控的错误信息')
                    }, videoEl ? videoEl : `camera_${id}`,
                    {
                        url: cameraStreamUrl
                    }
                );
                this.videoPlayers[id] = player;
            } catch (err) {
                console.log(err, '初始化监控错误信息');
            }
        },
        changeTabBar(index){
            this.tabBarType = index;
        },
        turnOn(item, val){
            item.val = val;
        },
        /**
         * 查询异常抓拍
         * @param {Number} pageNo 页码
         * @param {Number} pageSize 数据量
         * */
        getAbnormalData(pageNo = 1, pageSize = 10){
          this.$fly.post(queryCapturePhotoAbnormalUrl, {
            ...this.queryParams,
            pageNo,
            pageSize
          })
          .then(res => {
            if (res.code !== 0) {
              return ;
            }
            this.$vc.emit(this.$route.path, "pagination", "init", {
              total: res.data.total,
              currentPage: pageNo,
              pageSize,
            })
            this.abnormalInfoList = res.data.datas || [];
          })
        },
        // 保存配置
        saveStandard(){
          let params = {};
          Object.entries(this.abnormalItems).forEach(([key, val]) => {
            params[key] = val.val;
          })
          this.$fly.post(saveAbnormalPushConfigUrl, params)
          .then(res => {
            if (res.code !== 0) {
              return ;
            }
            this.$vc.toast('保存成功')
          })
        },
        // 工单推送配置
        getAbnormalPushConfig() {
          this.$fly.post(queryAbnormalPushConfigUrl)
          .then(res => {
            if (res.code !== 0) {
              return ;
            }
            for(let key in this.abnormalItems) {
              this.abnormalItems[key] = res.data[key];
            }
          })
        },
        checkDealResult(target){
            this.dealResultVisible = true;
            this.handlerResult = {
              text: target.handlerResult,
              photo: target.handlerPhoto,
            };
        },
        checkImg(src, title = "查看大图"){
            this.$vc.emit(this.$route.path, "viewImageModal","view",{
                photos: [src],
                title
            })
        },
        checkVideo(){
            var data = {
                boolean:true,
            }
            this.$vc.emit(this.$route.path, "abnormalManage","checkFile",data)
        }
    },
}
</script>

<style lang="stylus" scoped>
.config
    &-panel
        width 100%
        background #fff
        height 100%
        min-height 500px
        box-shadow 0 5px 10px 0 rgba(0, 0, 0, 0.1)
    &-item
        line-height 28px
        padding 7px 0px
        div
            display inline-block
        &-label
            color #000
            height 20px
            margin-right 30px
            span
                vertical-align middle
        button
            margin-left 30px
.config-item-radio
    margin-left 30px
    height 30px
    label
      &:not(:last-of-type)
        margin-right 20px
    span
      display inline-block
.class-a
    position relative
    margin-right 10px
    top 5px
    left 0
    display inline
    width 20px
    height 20px
    background url('../../assets/icon5.png')
.class-b
    position relative
    margin-right 10px
    top 5px
    left 0
    display inline
    width 20px
    height 20px
    background url('../../assets/icon3.png')
.static
    margin 0 10px
.time_
    display inline-block
    width 43px !important
</style>
